import React from 'react'
import '../styles/Projects.css'

export default function ProjectTile(props){
    return(
        <div className="projectTile">
            <img className="projectImage" src={props.image} alt={props.title}></img>
            <section className="overlay"></section>
            <h2 className="projectTitle">{props.title}</h2>
            {props.published ? <p className="projectBlurb">{props.desc}<a target="_blank" rel="noopener noreferrer" href={props.link}>here</a>.<br/>
                Technologies used: {props.tech}
            </p> : <p className="projectBlurb">*Coming Soon*</p>}
            
        </div>
    )
}