import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import '../styles/About.css'
import photo from '../img/cartoon.png'
import photo2 from '../img/normal.png'
import Typewriter from "typewriter-effect"

export default function About(){

    const [isAnimationEnded, setIsAnimationEnded] = useState(false)

    const skipAnimation = function(){
        setIsAnimationEnded(true)
    }

    return (
        <div className='cardContainer'>
            <div className="images">
                <img alt="me" className="bottom" src={photo2}/>
                <img alt="cartoon me" className="top" src={photo}/>
            </div>
            <div className="details">
                {isAnimationEnded &&<> <div className="detailSection">
                        <h3 className="detailTitle">Name: </h3>
                        <h3 className="detailAnswer">Chia</h3>
                    </div>
                    <div className="detailSection">
                        <h3 className="detailTitle">Education: </h3>
                        <h3 className="detailAnswer">MSci Computer Science</h3>
                    </div>
                    <div className="detailSection">
                        <h3 className="detailTitle">Hobbies: </h3>
                        <h3 className="detailAnswer">Music, Sports, Technology, Travel</h3>
                    </div>
                    <div className="detailSection">
                        <h3 className="detailTitle">Role: </h3>
                        <h3 className="detailAnswer">Computer Scientist, Technologist, </h3>
                        <h3 className="detailAnswer2">Developer, Designer, Analyst</h3>
                    </div></>}
                    {!isAnimationEnded && <> <div className="detailSection">
                    <h3 className="detailTitle">
                        <Typewriter
                        onInit={typewriter => {
                            typewriter
                            .changeDeleteSpeed(1)
                            .changeDelay(40)
                            .typeString("Name:")
                            .pauseFor(400)
                            .typeString("<span style='font-weight: lighter'> Chia </span>")
                            .pauseFor(400)
                            .typeString("<br>Education:")
                            .pauseFor(400)
                            .typeString("<span style='font-weight: lighter'> MSci Computer Science </span>")
                            .pauseFor(400)
                            .typeString("<br>Hobbies:")
                            .pauseFor(400)
                            .typeString("<span style='font-weight: lighter'> Music, Sports, Technology, Travel</span>")
                            .pauseFor(400)
                            .typeString("<br>Role:")
                            .pauseFor(400)
                            .typeString("<span style='font-weight: lighter'> Computer Scientist, Technologist,</span>")
                            .typeString("<span style='font-weight: lighter'><br>Developer, Designer, Analyst</span>")
                            .callFunction(()=>setIsAnimationEnded(true))
                            .start()
                           
                        }}
                    
                        />
                    </h3>
                    
                </div></>}
                
            </div>
            <div className="btnContainer">
                    {isAnimationEnded ? <div><Link to="/projects" className="after">NEXT</Link> </div>: <div onClick={skipAnimation} className="btn toggle"><span className="before">SKIP</span></div>}
            </div>

        </div>
    )
}