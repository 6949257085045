import React from 'react';
import './App.css';
import Navbar from './components/Navbar'
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom'
import Header from './components/Header'
import bgVideo from './img/bg.mp4'
import About from './contents/About'
import Projects from './contents/Projects'
import Contact from './contents/Contact'
import NotFound from './components/NotFound';

function App(){
  return (
    <div id='page'>
      <video id='bg' autoPlay muted loop playsInline webkit-playsInline>
        <source src={bgVideo} type='video/mp4'/>
      </video>
      <Router>
        <div className='navigation'>
          <Navbar></Navbar>
        </div>
        <div id='content'>
        <Switch>
          <Route exact path="/">
            <Header className='title'></Header>
          </Route>
          <Route path="/about">
            <About></About>
          </Route>
          <Route path="/projects">
            <Projects></Projects>
          </Route>
          <Route path="/contact">
            <Contact></Contact>
          </Route>
          <Route path="/404">
            <NotFound></NotFound>
          </Route>
          <Redirect from='*' to='/404'></Redirect>
        </Switch>
        </div>
      </Router>
    </div>
      
  );
}

export default App;
