import React from 'react'
import '../styles/Contact.css'
import linkedinPic from '../img/linkedin.png'
import githubPic from '../img/github.webp'
import gmailPic from '../img/gmail.webp'

export default function Contact(){
    return(
        <div className="contactContainer">
            <div className="topRow">
                <a href="https://uk.linkedin.com/in/chiamaka-anamekwe" target="_blank" rel="noopener noreferrer" id="linkedin" className="contactTile grow" style={{backgroundColor: "#A9A9A9"}}>
                    <h3>LinkedIn</h3>
                    <a href="https://uk.linkedin.com/in/chiamaka-anamekwe">Chiamaka Anamekwe</a>
                    <img src={linkedinPic} alt="linkedin"></img>
                </a>
                <a href="https://github.com/canams" target="_blank" rel="noopener noreferrer" id="github" className="contactTile grow" style={{backgroundColor: "#696969"}}>
                    <h3>GitHub</h3>
                    <a href="https://github.com/canams">canams</a>
                    <img src={githubPic} alt="github"></img>
                </a>
            </div>
            <div className="bottomRow">
                <a href="mailto:canamsenquiries@gmail.com" target="_blank" rel="noopener noreferrer" id="email" className="contactTile grow" style={{backgroundColor: "#454444"}}>
                    <h3>Email</h3>
                    <a href="mailto:canamsenquiries@gmail.com">canamsenquiries@gmail.com</a>
                    <img src={gmailPic} alt="gmail"></img>
                </a>
            </div>
        </div>
    )
}