import React from 'react'
import {NavLink} from 'react-router-dom'
import '../styles/Navbar.css'


export default function NavItem(props){
   
    return(
        <li>
            <NavLink className="navLink grow" activeClassName="active" exact to={props.link}>{props.page}</NavLink>
        </li>
    )

}
