import React from 'react'
import '../styles/Projects.css'
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css'
import ProjectTile from '../components/ProjectTile'
import gamePhoto from '../img/games.jpg'
import botPhoto from '../img/robot.jpg'
import moneyPhoto from '../img/money.jpg'
import musicPhoto from '../img/music.jpg'
//import AwsSliderStyles from 'react-awesome-slider/src/styles.scss';

export default function Projects(){

    return(
        <AwesomeSlider>
          <div className="projectsContainer">
              <ProjectTile 
                title="Minesweeper" 
                image={gamePhoto} 
                desc="A reimplementation of the timeless puzzle game. Can be downloaded " 
                link={`https://canams.github.io/minesweeper/`}
                tech="Java, Swing"
                published={true}
                >
              </ProjectTile>
          </div>
          <div>
            <ProjectTile 
                title="Chatbot" 
                image={botPhoto}
                desc="A general knowledge, question answering bot. Check out the repo "
                link="https://github.com/canams/chatbot"
                tech="Python, NumPy, NLTK, Scikit-learn"
                published={true}
                >
            </ProjectTile>
          </div>
          <div>
            <ProjectTile 
                title="Financial Advisor" 
                image={moneyPhoto}
                desc="A handy budgeting tool. Try it out "
                link="https://canams.github.io/finance-advisor/"
                tech="TypeScript"
                published={false}
                ></ProjectTile>
          </div>
          <div>
            <ProjectTile    
                title="Music Recommender" 
                image={musicPhoto}
                desc=""
                link=""
                tech=""
                published={false}
                ></ProjectTile>
          </div>
        </AwesomeSlider>
    )
}