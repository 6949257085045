import React from 'react'
import '../styles/Header.css'

export default function Header(){
    return(
        <div className="title">
            <h1 className="glitchText">Welcome to my portfolio.</h1>
            <h1 className="glitchText">Welcome to my portfolio.</h1>
            <h1 className="glitchText">Welcome to my portfolio.</h1>
            <div id='blurbContainer'>
                <ul className="blurbList">
                    <li className="blurbListItem">• Computer Scientist •</li>
                    <li className="blurbListItem">• Technologist •</li>
                    <li className="blurbListItem">• Data Scientist •</li>
                    <li className="blurbListItem">• Designer •</li>
                </ul>
            </div>
        </div>
        
    )
}