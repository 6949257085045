import React from 'react'
import { Link } from 'react-router-dom'
import '../styles/NotFound.css'

export default function NotFound(){
    return(
        <div className="notFoundContainer">
            <h3 className="notFoundHead">Well... this is awkward. </h3>
            <p className="notFoundBody">Seems I've led you down the wrong path. Let's head back to normality shall we?</p>
            <div className="btnContainer notFoundBtn">
                <Link to="/" className="after">GO HOME</Link>
            </div>
        </div>
    )
}