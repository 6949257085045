import React from 'react';
import NavItem from './NavItem'
import '../styles/Navbar.css'


const PAGES = [{title:"Home", route:"/"},{title:"About", route:"/about"},{title:"Projects", route:"/projects"},{title:"Contact", route:"/contact"}]

export default function Navbar(){
    return(
        <nav>
            <ul>
                {PAGES.map(page =><NavItem page={page.title} link={page.route} key={page.title}></NavItem> )}

            </ul>
        </nav>
    )
}

